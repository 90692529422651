import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Gift, PauseCircle, Heart, ThumbsUp, AlertTriangle, MessageCircle, Clock, Sunrise, Radio, LifeBuoy } from 'react-feather';

import iconResponsability from '../../images/solutions/icon-responsability.svg';
import iconRegret from '../../images/solutions/icon-regret.svg';
import iconReparation from '../../images/solutions/icon-reparation.svg';

const PredictiveAssessment = () => {

    return (
        <Layout>
            <SEO title="Predictive Assessments" />
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="page-intro">
                        <h1 className="h3">Restorativ predicts the most efficient and effective way to restore relationships.</h1>
                        <p className='lead'>Everyone is unique. Restorativ recognizes that there are different types of learners. We have built a predictive assessment that the Responsible Party will take to help plot the most effective path to restoration. Our technology is designed to carefully manage each Responsible Party’s path by tailoring tools and support for an effective restorative practice. Our Assessment focuses on these key indicators:</p>
                    </div>

                    {/* <ul className="my-4 text-center predictive">
                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <Sunrise />
                            </div>
                            <p className='bold'>Feelings first</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <PauseCircle />
                            </div>
                            <p className='bold'>Pause before you act</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <Radio />
                            </div>
                            <p className='bold'>Control your thoughts</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <AlertTriangle />
                            </div>
                            <p className='bold'>Criticism is beneficial</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <Heart />
                            </div>
                            <p className='bold'>Empathy</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <MessageCircle />
                            </div>
                            <p className='bold'>Giving helpful feedback</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <Gift />
                            </div>
                            <p className='bold'>Apologize</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <ThumbsUp />
                            </div>
                            <p className='bold'>Forgive and forget</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <Clock />
                            </div>
                            <p className='bold'>Keeping commitments</p>
                        </li>

                        <li className='predictive__item'>
                            <div className="predictive__icon">
                                <LifeBuoy />
                            </div>
                            <p className='bold'>You help others</p>
                        </li>
                    </ul> */}
                </div>

            </div>

            <div className="section-primary">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row text-center mt-3">
                            <div className="col-md-4 d-flex mb-3 ">
                                <div className="card">
                                    <div className="card-body">
                                        <img className='mx-auto mb-2' src={iconResponsability} alt='' />
                                        <h3 className="h4">Responsibility</h3>
                                        <p>Acknowledging the harmful behaviour and the effect it had on others</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 d-flex mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img className='mx-auto mb-2' src={iconRegret} alt='' />
                                        <h3 className="h4">Regret</h3>
                                        <p>Presenting a sincere apology to the Affected Parties showing acknowledgment, affect, and vulnerability</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 d-flex mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img className='mx-auto mb-2' src={iconReparation} alt='' />
                                        <h3 className="h4">Reparation</h3>
                                        <p>Completing a restorative agreement that repairs the harm, provides restitution, and initiates meaningful change</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default PredictiveAssessment
